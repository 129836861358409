import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Meta from "../components/Meta"
import FlexibleFields from "../components/FlexibleFields"

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      flexibleContent {
        content {
          __typename
          ... on WpPage_Flexiblecontent_Content_About {
            aboutType
            scaleMomentum {
              powerUp {
                copy
              }
              powerUpCopy
              teamUp {
                copy
              }
              teamUpCopy
            }
            powerupTeamup {
              powerupCopy
              teamupCopy
            }
            proprietaryTechnology {
              copy
            }
          }
          ... on WpPage_Flexiblecontent_Content_CareerDetails {
            backgroundTextLabel
          }
          ... on WpPage_Flexiblecontent_Content_CareerForm {
            title
          }
          ... on WpPage_Flexiblecontent_Content_CareerHeader {
            background {
              sourceUrl
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_CareersArchive {
            careerArchiveType
          }
          ... on WpPage_Flexiblecontent_Content_Column {
            heading
            content
            position
          }
          ... on WpPage_Flexiblecontent_Content_Contact {
            contactType
            title
            getInTouchContent {
              title
              titleSize
              hideDiagonalSlice
              background {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
              button {
                label
                link
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_CtaText {
            title
            content
            backgroundText {
              line
            }
            flip
          }
          ... on WpPage_Flexiblecontent_Content_GameDetails {
            title
            headshot {
              sourceUrl
            }
            quote
            name
            showLower
            lowerTitle
            lowerStatistic
            lowerLabel
            lowerImage {
              sourceUrl
            }
          }
          ... on WpPage_Flexiblecontent_Content_GameHeader {
            foreground {
              sourceUrl
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
            }
            background {
              sourceUrl
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_GameDetails {
            title
            headshot {
              sourceUrl
            }
            quote
            name
            showLower
            lowerTitle
            lowerStatistic
            lowerLabel
            lowerImage {
              sourceUrl
            }
          }
          ... on WpPage_Flexiblecontent_Content_GameResults {
            results {
              thumbnail {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
              content {
                mediaItemUrl
                sourceUrl
              }
              statistic
              unit
            }
          }
          ... on WpPage_Flexiblecontent_Content_GamesArchive {
            gamesArchiveType
            sliderContent {
              game {
                ... on WpGame {
                  title
                  slug
                  platforms {
                    nodes {
                      slug
                      platformFields {
                        icon {
                          sourceUrl
                        }
                      }
                    }
                  }
                  gameFields {
                    thumbnail {
                      sourceUrl
                      mediaDetails {
                        sizes {
                          name
                          sourceUrl
                        }
                      }
                    }
                    videoThumbnail {
                      sourceUrl
                    }
                    video {
                      mediaItemUrl
                    }
                    legals
                  }
                }
              }
              bigText
              smallText
              foreground {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
              midground {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
            }
            featuredContent {
              game {
                ... on WpGame {
                  title
                  slug
                  platforms {
                    nodes {
                      slug
                      platformFields {
                        icon {
                          sourceUrl
                        }
                      }
                    }
                  }
                  gameFields {
                    thumbnail {
                      sourceUrl
                      mediaDetails {
                        sizes {
                          name
                          sourceUrl
                        }
                      }
                    }
                    videoThumbnail {
                      sourceUrl
                    }
                    video {
                      mediaItemUrl
                    }
                    logo {
                      sourceUrl
                      mediaDetails {
                        sizes {
                          name
                          sourceUrl
                        }
                      }
                    }
                    legals
                  }
                }
              }
              foreground {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
              midground {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
              background {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_GlassdoorReviews {
            reviews {
              review
              rating
            }
          }
          ... on WpPage_Flexiblecontent_Content_Hero {
            title
            backgroundType
            background {
              sourceUrl
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
            }
            tagline
            social
          }
          ... on WpPage_Flexiblecontent_Content_Locations {
            title
          }
          ... on WpPage_Flexiblecontent_Content_NewsHeader {
            title
            author
            published
          }
          ... on WpPage_Flexiblecontent_Content_OurOfferings {
            offerings {
              title
              tagline
              description
              powerUp
              teamUp
            }
          }
          ... on WpPage_Flexiblecontent_Content_Partners {
            partnersType
          }
          ... on WpPage_Flexiblecontent_Content_GamesList {
            title
            gamelist {
              gameimage {
                sourceUrl
              }
              gamename
              gamelink
            }
          }
          ... on WpPage_Flexiblecontent_Content_WhoWeAreContent {
            whoweareitems {
              image1 {
                sourceUrl
              }
              description1
              image2 {
                sourceUrl
              }
              description2
              image3 {
                sourceUrl
              }
              description3
              image4 {
                sourceUrl
              }
              description4
              image5 {
                sourceUrl
              }
              description5
              image6 {
                sourceUrl
              }
              description6
            }
            milestones {
              milestonename
              milestonedescription
              milestoneimage {
                sourceUrl
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_PostArchive {
            postArchiveType
            imageRowsContent {
              post {
                ... on WpPost {
                  title
                  content
                  slug
                  postFields {
                    offSitePost
                    postUrl
                    thumbnail {
                      mediaItemUrl
                    }
                  }
                }
              }
            }
            featuredContent {
              post {
                ... on WpPost {
                  title
                  slug
                  postFields {
                    offSitePost
                    postUrl
                    thumbnail {
                      mediaItemUrl
                    }
                  }
                }
              }
              background {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
              highlights {
                image {
                  sourceUrl
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_ProprietaryTechnology {
            technologies {
              name
              title
              tagline
              copy
              image {
                sourceUrl
              }
              background {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
              theme
            }
          }
          ... on WpPage_Flexiblecontent_Content_StaticImage {
            image {
              sourceUrl
            }
            subtitle
            hasCredit
            credit
          }
          ... on WpPage_Flexiblecontent_Content_Team {
            teamMembers {
              headshot {
                sourceUrl
              }
              name
              jobTitle
              description
              socialLinks {
                linkedin {
                  url
                }
                facebook {
                  url
                }
                twitter {
                  url
                }
                instagram {
                  url
                }
                youtube {
                  url
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_Testimonials {
            testimonials {
              headshot {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
              name
              jobTitle
              testimonial
            }
            theme
            title
            description
          }
          ... on WpPage_Flexiblecontent_Content_Video {
            title
            game {
              ... on WpGame {
                title
                slug
                platforms {
                  nodes {
                    slug
                    platformFields {
                      icon {
                        sourceUrl
                      }
                    }
                  }
                }
                gameFields {
                  thumbnail {
                    sourceUrl
                    mediaDetails {
                      sizes {
                        name
                        sourceUrl
                      }
                    }
                  }
                  videoThumbnail {
                    sourceUrl
                  }
                  video {
                    mediaItemUrl
                  }
                  legals
                  links {
                    amazonAppStore
                    googlePlay
                    iosAppStore
                    macAppStore
                    microsoftStore
                    samsungGalaxyStore
                    steam
                  }
                }
              }
            }
          }
        }
      }
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      content
      slug
      date(formatString: "DD-MM-YYYY")
      author {
        node {
          name
        }
      }
    }
  }
`

export default ({ data: { wpPage } }) => {
  let combinedGamesList = []
  let firstGamesListIndex = -1

  const resultContent = wpPage?.flexibleContent?.content
    ? wpPage?.flexibleContent?.content
        .map((item, index) => {
          if (item.__typename === "WpPage_Flexiblecontent_Content_GamesList") {
            if (firstGamesListIndex === -1) {
              firstGamesListIndex = index
            }
            combinedGamesList = combinedGamesList.concat(
              item.gamelist.map(game => ({
                title: item.title,
                gamelist: item.gamelist,
              }))
            )
            return null
          } else {
            return item
          }
        })
        .filter(item => item !== null)
    : []
  const combinedGamesListObject = {
    __typename: "WpPage_Flexiblecontent_Content_GamesList",
    gamelist: combinedGamesList,
  }

  if (firstGamesListIndex !== -1 && wpPage?.flexibleContent?.content) {
    resultContent.splice(firstGamesListIndex, 0, combinedGamesListObject)
  }
  return (
    <Layout>
      <Meta seo={wpPage?.seo} />
      <FlexibleFields data={wpPage} content={resultContent ?? []} />
    </Layout>
  )
}
